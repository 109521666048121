import { React } from 'react';
import { Container, Row, Col, Image, Carousel } from 'react-bootstrap';
import  PropTypes from 'prop-types';

import NavigationBar from '../components/NavigationBar';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

import MetalProPageBackground from '../static/assests/background/metal-pro-page-background.jpg';
import SteelBackground from '../static/assests/background/steel-background.png';
import LongevityIcon from '../static/assests/icons/longevity-icon.svg';
import VersatileDataCapacityIcon from '../static/assests/icons/versatile-data-capacity-icon.svg';
import HighResolutionIcon from '../static/assests/icons/high-resolution-icon.svg';
import MakeInIndiaLogo from '../static/assests/logos/make-in-india-logo.png';
import WeatherIcon from '../static/assests/icons/weather-icon.svg';
import AbrasionIcon from '../static/assests/icons/abrasion-icon.svg';
import TemperatureIcon from '../static/assests/icons/temperature-icon.svg';
import CorrosionIcon from '../static/assests/icons/corrosion-icon.svg';
import ChemicalIcon from '../static/assests/icons/chemical-icon.svg';
import PrintingProcessBackground from '../static/assests/background/printing-process-background.jpg';
import MetalProLogo from '../static/assests/logos/metal-pro-logo.png';
import TestResults from '../static/assests/metal-pro/test-results.jpg';

import '../static/styles/Common.css';

function MetalPro(props) {
    // Container Style
    const containerStyle = {
        padding: '0% 10%',
    }

    // Screen and Digital Printing Process Style
    const processBackgroundStyle = {
        backgroundImage: `url(${PrintingProcessBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '15rem',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        backgroundBlendMode: 'multiply',
    };

    // Industries Container Style
    const industryContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alighItems: 'center',
        padding: '0% 10%',
    }

    // Names of Industries
    const industryNames = [
        {
            id: 1,
            name: 'Chemicals'
        },
        {
            id: 2,
            name: 'Pharmaceuticals'
        },
        {
            id: 3,
            name: 'Marine'
        },
        {
            id: 4,
            name: 'Paints'
        },
        {
            id: 5,
            name: 'Dairy'
        },
        {
            id: 6,
            name: 'Textile'
        },
        {
            id: 7,
            name: 'Cooling'
        },
        {
            id: 8,
            name: 'Manufacturing'
        },
        {
            id: 9,
            name: 'Automobile'
        },
        {
            id: 10,
            name: 'Hospitals'
        }
    ]

    // Names of Industrial Applications
    const industryApplicationNames = [
        {
            id: 1,
            name: 'Asset Tagging/Labels'
        },
        {
            id: 2,
            name: 'Bar Codes'
        },
        {
            id: 3,
            name: 'Callibration Labels'
        },
        {
            id: 4,
            name: 'Name Plates'
        },
        {
            id: 5,
            name: 'Safety Signs'
        },
        {
            id: 6,
            name: 'Indoor-Outdoor Panels'
        },
        {
            id: 7,
            name: 'Shop-floor Activity'
        },
        {
            id: 8,
            name: 'Equipment Labels'
        },
        {
            id: 9,
            name: 'Night Glow Labels'
        }
    ]

    // Sample Gallery
    const sampleGallery = [
        {
            id: 1,
            name: 'gallery-1.jpg'
        },
        {
            id: 2,
            name: 'gallery-2.jpg'
        },
        {
            id: 3,
            name: 'gallery-3.jpg'
        },
        {
            id: 4,
            name: 'gallery-4.jpg'
        },
        {
            id: 5,
            name: 'gallery-5.jpg'
        },
        {
            id: 6,
            name: 'gallery-6.jpg'
        },
        {
            id: 7,
            name: 'gallery-7.jpg'
        },
        {
            id: 8,
            name: 'gallery-8.jpg'
        },
        {
            id: 9,
            name: 'gallery-9.jpg'
        },
        {
            id: 10,
            name: 'gallery-10.jpg'
        },
        {
            id: 11,
            name: 'gallery-11.jpg'
        },
        {
            id: 12,
            name: 'gallery-12.jpg'
        },
        {
            id: 13,
            name: 'gallery-13.jpg'
        }
    ]

    // Product Gallery
    const productGallery = [
        {
            id: 1,
            name: 'product-1.jpg'
        },
        {
            id: 2,
            name: 'product-2.jpg'
        },
        {
            id: 3,
            name: 'product-3.jpg'
        },
        {
            id: 4,
            name: 'product-4.jpg'
        },
        {
            id: 6,
            name: 'product-6.jpg'
        },
        {
            id: 7,
            name: 'product-7.jpg'
        },
        {
            id: 8,
            name: 'product-8.jpg'
        },
        {
            id: 9,
            name: 'product-9.jpg'
        },
        {
            id: 12,
            name: 'product-12.jpg'
        },
        {
            id: 13,
            name: 'product-13.jpg'
        },
        {
            id: 14,
            name: 'product-14.jpg'
        },
        {
            id: 15,
            name: 'product-15.jpg'
        }
    ]

    return (
        <>
            {/* Navigation Bar */}
            <NavigationBar navKey={props.navKey} />
            
            {/* Hero */}
            <Hero
                image={MetalProPageBackground}
                content={heroContent()} 
                style={{}}
                mobileHeight={'90vh'}
            />

            {/* Introduction */}
            <div style={{backgroundImage: `url(${SteelBackground})`}}>
                <Container className='text-center py-5' style={containerStyle}>
                    <p className='lato-regular font-medium py-3'>
                        The exceptional combination of durability, image clarity, and variable data functionality sets METALPRO™ 
                        Labels apart from other graphic identification materials.
                    </p>
                    <Row>
                        <Col md={4}>
                            <Image src={LongevityIcon} width={80} height={80} />
                            <p className='lato-regular font-medium py-3'>
                                Longevity
                            </p>
                        </Col>
                        <Col md={4}>
                            <Image src={VersatileDataCapacityIcon} width={80} height={80} />
                            <p className='lato-regular font-medium py-3'>
                                Versatile Data Capacity
                            </p>
                        </Col>
                        <Col md={4}>
                            <Image src={HighResolutionIcon} width={80} height={80} />
                            <p className='lato-regular font-medium py-3'>
                                High Resolution
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className='py-3'>
                            <Image src={MakeInIndiaLogo} fluid />
                        </Col>
                        <Col md={6} className='py-3'>
                            <p className='lato-regular font-medium text-md-start'>
                                MetalPRO™ Labels is 100% developed and Make in India name plate product. We manufactured high quality 
                                Aluminium Anodised Sheets which can be used for Screen as well as Digital printing processes.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Why MetalPro Labels? */}
            <Container className='lato-regular font-regular text-center py-5' style={containerStyle}>
                <p className='lato-bold font-large'>
                    Why METALPRO™ Labels?
                </p>
                <p>
                    Because of its ability to perform within and across a range of challenging environments, METALPRO™ meets an array 
                    of government, and industrial specifications where permanent product identification is critical.
                </p>
                <p>
                    10 - year- plus life expectancy in high heat, abrasion, chemicals and marine, saltwater exposure. When requiring 
                    durability and variable data in the form of barcodes, QR codes and item unique instructions, data, security printing or anti-counterfeit.
                </p>
                <Row className='text-center'>
                    <Col xs={6} sm className='p-2 p-md-4'>
                        <Image src={WeatherIcon} width={100} height={100} />
                        <p className='lato-bold font-regular py-3'>
                            Weather/UV durability
                        </p>
                    </Col>
                    <Col xs={6} sm className='p-2 p-md-4'>
                        <Image src={AbrasionIcon} width={100} height={100} />
                        <p className='lato-bold font-regular py-3'>
                            Protection against abrasion
                        </p>
                    </Col>
                    <Col xs={6} sm className='p-2 p-md-4'>
                        <Image src={TemperatureIcon} width={100} height={100} />
                        <p className='lato-bold font-regular py-3'>
                            Withstanding temperature changes
                        </p>
                    </Col>
                    <Col xs={6} sm className='p-2 p-md-4'>
                        <Image src={CorrosionIcon} width={100} height={100} />
                        <p className='lato-bold font-regular py-3'>
                            Enhanced resistance to corrosion
                        </p>
                    </Col>
                    <Col xs={12} sm className='p-2 p-md-4'>
                        <Image src={ChemicalIcon} width={100} height={100} />
                        <p className='lato-bold font-regular py-3'>
                            Resistance to chemicals
                        </p>
                    </Col>
                </Row>
                <div className='border border-dark rounded p-md-5 p-1'>
                    <p className='lato-bold font-large text-center pb-md-3 m-0'>
                        Test Results
                    </p>
                    <p className='lato-regular font-regular text-center pb-md-3 m-0'>
                        The ability to perform in a range of challenging environments,
                    </p>
                    <Image src={TestResults} fluid />
                </div>
            </Container>

            {/* Industries */}
            <div className='p-5' style={{backgroundColor: '#F3F3F3'}}>
                <p className='lato-bold font-large text-center py-3'>
                    The kind of industries, we serve are
                </p>
                <Container className='d-flex' style={industryContainerStyle}>
                    {industryNames.map(project => generateIndustries(project))}
                </Container>
                <br />
                <p className='lato-bold font-large text-center py-3'>
                    Industrial applications
                </p>
                <Container className='d-flex' style={industryContainerStyle}>
                    {industryApplicationNames.map(project => generateIndustries(project))}
                </Container>
            </div>

            {/* Screen and Digital Printing Process */}
            <div style={processBackgroundStyle} className='p-5'>
                <Container style={containerStyle}>
                    <Row>
                        <p className='text-center white lato-regular font-large pb-5'>
                            Screen and Digital Printing Process
                        </p>
                        <Col md={6} className='lato-regular font-regular white p-md-5 px-4'>
                            <p>
                            We utilize unique screen printing inks to print on anodized aluminium plates that are custom-made by our company. 
                            We take pride in manufacturing these plates in-house, ensuring their precise specifications are met. 
                            </p>
                            <p>
                                Our company utilizes advanced digital printing machines to produce multi-color printing on anodized aluminium 
                                plates of exceptional quality. With the capability to work with high resolution images and an unlimited range of colors, 
                                our digital printing process offers unparalleled flexibility and precision.
                            </p>
                        </Col>
                        <Col md={6} className='lato-regular font-regular white p-md-5 px-4'>
                            <p>
                                Following the printing procedure, all the printed plates are stored in dedicated ovens at a predetermined temperature 
                                for a specific duration.
                            </p>
                            <p>
                                Subsequently, a unique treatment is applied to enhance the longevity of the plates. 
                                These plates are offered in halftone colors of Black, Red, Blue, Yellow, and Cyan. 
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* Metal Plates Sample Gallery */}
            <Container className='py-5 gallery'>
                <p className='lato-bold font-large text-center py-2'>
                    Metal Plates Sample Gallery
                </p>
                <Carousel className='py-2' variant='dark' slide={false}>
                    {sampleGallery.map(project => generateImages(project))}
                </Carousel>
                <p className='lato-bold font-large text-center pt-5 pb-2'>
                    Metal Plates on Products
                </p>
                <Carousel className='py-2' variant='dark' slide={false}>
                    {productGallery.map(project => generateImages(project))}
                </Carousel>
            </Container>

            {/* Footer */}
            <Footer />
        </>
    );
}

// Hero Element Center Content
function heroContent() {
    return (
        <Image src={MetalProLogo} width={400} height={200} fluid />
    );
}

// Generating Industry Names
function generateIndustries(project) {
    return (
        <p className='lato-bold font-regular p-2 p-md-3 m-1 metal linear' key={project.id}>
            {project.name}
        </p>
    );
}

// Generating Images for Carousel
function generateImages(project) {
    const importAll = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace('./', '')] = r(item));
        });
        return images;
    };
      
    const images = importAll(require.context('../static/assests/metal-pro/', false, /\.(png|jpe?g|svg)$/));

    return (
        <Carousel.Item className='text-center' key={project.id} interval={1000}>
            <Image src={images[project.name]} fluid className='carousel-image' />
        </Carousel.Item>
    );
}

MetalPro.propTypes = {
    navKey: PropTypes.string,
};

export default MetalPro;