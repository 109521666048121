import { React, useEffect, useState } from 'react';
import { Navbar, Nav, Image, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

import StrokesIndiaLogo from '../static/assests/logos/strokes-india-logo.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../static/styles/Common.css';

function NavigationBar(props) {
    // Mobile View Port Flag
    const [isMobile, setIsMobile] = useState(false);

    // Hovered Nav Link Style Variable
    const [hoveredNavLink, setHoveredNavLink] = useState(null);

    // Listener for Mobile View Port
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 991.98);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Navigation Bar Style
    const navbarStyle = isMobile ? { backgroundColor: 'rgba(255, 255, 255, 1)' } : { backgroundColor: 'rgba(255, 255, 255, 0.9)' };

    // Container Style
    const containerStyle = isMobile ? { width: '100%' } : { width: 'auto' };

    return (
        <Navbar fixed='top' collapseOnSelect expand='lg' style={navbarStyle}>
            <Container style={containerStyle}>

                {/* Brand Logo */}
                <Navbar.Brand href='/home' className='me-xl-5'>
                    <Image src={StrokesIndiaLogo} width={240} height={60} fluid />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar-nav'>
                </Navbar.Toggle>

                {/* Nav Items */}
                <Navbar.Collapse id='responsive-navbar-nav' className='justify-content-center'>
                    <Nav activeKey={props.navKey} className='lato-regular font-regular dark-gray ms-xl-5'>
                        <Nav.Link 
                            eventKey={'metal-pro'} 
                            className='px-lg-2 px-xl-3' 
                            href='/metal-pro'
                            style={{
                                color: props.navKey === 'metal-pro' || hoveredNavLink === 'metal-pro' ? '#ee5a22' : 'inherit'
                            }}
                            onMouseEnter={() => setHoveredNavLink('metal-pro')}
                            onMouseLeave={() => setHoveredNavLink(null)}
                        >
                            METALPRO
                        </Nav.Link>
                        <Nav.Link 
                            eventKey={'metal-photo'} 
                            className='px-lg-2 px-xl-3' 
                            href='/metal-photo'
                            style={{
                                color: props.navKey === 'metal-photo' || hoveredNavLink === 'metal-photo' ? '#ee5a22' : 'inherit'
                            }}
                            onMouseEnter={() => setHoveredNavLink('metal-photo')}
                            onMouseLeave={() => setHoveredNavLink(null)}
                        >
                            METALPHOTO
                        </Nav.Link>
                        <Nav.Link 
                            eventKey={'design-wallpaper'} 
                            className='px-lg-2 px-xl-3' 
                            href='/design-wallpaper'
                            style={{
                                color: props.navKey === 'design-wallpaper' || hoveredNavLink === 'design-wallpaper' ? '#ee5a22' : 'inherit'
                            }}
                            onMouseEnter={() => setHoveredNavLink('design-wallpaper')}
                            onMouseLeave={() => setHoveredNavLink(null)}
                        >
                            DESIGN & WALLPAPER
                        </Nav.Link>
                        <Nav.Link 
                            eventKey={'about-us'} 
                            className='px-lg-2 px-xl-3' 
                            href='/about-us'
                            style={{
                                color: props.navKey === 'about-us' || hoveredNavLink === 'about-us' ? '#ee5a22' : 'inherit'
                            }}
                            onMouseEnter={() => setHoveredNavLink('about-us')}
                            onMouseLeave={() => setHoveredNavLink(null)}
                        >
                            ABOUT US
                        </Nav.Link>
                        <Nav.Link 
                            eventKey={'contact-us'} 
                            className='px-lg-2 px-xl-3' 
                            href='/contact-us'
                            style={{
                                color: props.navKey === 'contact-us' || hoveredNavLink === 'contact-us' ? '#ee5a22' : 'inherit'
                            }}
                            onMouseEnter={() => setHoveredNavLink('contact-us')}
                            onMouseLeave={() => setHoveredNavLink(null)}
                        >
                            CONTACT US
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

NavigationBar.propTypes = {
    navKey: PropTypes.string,
};

export default NavigationBar;
