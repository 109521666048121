import { React } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import  PropTypes from 'prop-types';

import NavigationBar from '../components/NavigationBar';
import Hero from '../components/Hero';
import RequestService from '../components/RequestService';
import Footer from '../components/Footer';

import ContactUsPageBackground from '../static/assests/background/contact-us-page-background.jpg';
import MapIcon from '../static/assests/icons/map-icon.png';
import MailIcon from '../static/assests/icons/mail-icon.png';
import PhoneIcon from '../static/assests/icons/phone-icon.png';
import LocationIcon from '../static/assests/icons/location-icon.png';

import '../static/styles/Common.css';

function ContactUs(props) {
    // Container Style
    const containerStyle = {
        padding: '0% 10%',
    }

    // Hero Style
    const heroStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderRadius: '10px',
        position: 'absolute',
        bottom: '0',
    }

    // Map Style
    const mapStyle = {
        width: '100%',
        height: '250px',
    }

    return (
        <>
            {/* Navigation Bar */}
            <NavigationBar navKey={props.navKey} />

            {/* Hero */}
            <Hero 
                image={ContactUsPageBackground}
                content={heroContent()}
                style={heroStyle}
                mobileHeight={'90vh'}
            />

            {/* Contact Us */}
            <Row className='g-0'>
                <Col md={12} lg={4} style={{backgroundColor: '#F2F2F2'}} className='order-lg-1 order-md-2 order-2'>
                    <Container style={containerStyle} className='py-5'>
                        <div className='p-2'>
                            <Image className='my-2' src={LocationIcon} width={40} height={40} />
                            <p className='lato-regular font-regular'>
                                Strokes India,<br />
                                691/A/2, Bibvewadi Industrial Estate No. 9,
                                2nd Floor, Pune Satara Road, Pune - 411037,
                                Maharashtra, India.
                            </p>
                        </div>
                        <div className='p-2'>
                            <Image className='my-2' src={PhoneIcon} width={40} height={40} />
                            <p className='lato-regular font-regular'>
                                Contact:<br />
                                <a href='tel:+91 9822065602' className='text-decoration-none black'>+91-9822065602</a><br />
                                <a href='tel:+91 9075095604' className='text-decoration-none black'>+91-9075095604</a><br />
                                <a href='tel:+91 9075095601' className='text-decoration-none black'>+91-9075095601</a>
                            </p>
                        </div>
                        <div className='p-2'>
                            <Image className='my-2' src={MailIcon} width={40} height={40} />
                            <p className='lato-regular font-regular'>
                                <a href='mailto:strokesindia@gmail.com' className='black text-decoration-none'>strokesindia@gmail.com</a><br />
                                <a href='mailto:sales.strokesindia@gmail.com' className='black text-decoration-none'>sales.strokesindia@gmail.com</a>
                            </p>
                        </div>
                        <div className='p-2'>
                            <Image className='my-2' src={MapIcon} width={40} height={40} /><br />
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7007.024926695676!2d73.85335036497273!3d18.483535132668205!2m3
                            !1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eaa75812484b%3A0xe4eb3933a019f8a2!2sStrokes%20India!5e0!3m2!1sen!2sin!4v1712
                            395704691!5m2!1sen!2sin" style={mapStyle} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Strokes India Location'></iframe>
                        </div>
                    </Container>
                </Col>
                <Col md={12} lg={8} className='order-md-1 order-lg-2 order-1'>
                    <Container style={containerStyle} className='py-5'>
                        <p className='lato-regular font-large text-center' style={{color: '#EE5A22'}}>
                            Request Service
                        </p>
                        <p className='lato-regular font-regular py-4'>
                            Come and visit our office or simply send us an email anytime you want. We are open to all queries 
                            and suggestions from our customers.
                        </p>
                        <RequestService />
                    </Container>
                </Col>
            </Row>

            {/* Footer */}
            <Footer />
        </>
    );
}

// Hero Element Center Content
function heroContent() {
    return (
        <p className='montserrat-bold font-title text-center white p-4 m-0'>
            Contact Us
        </p>
    );
}

ContactUs.propTypes = {
    navKey: PropTypes.string,
};

export default ContactUs;