import { React } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import FooterBackground from '../static/assests/background/footer-background.png';

import '../static/styles/Common.css';

function Footer() {
    // Background Image Style
    const backgroundImageStyle = {
        backgroundImage: `url(${FooterBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '15rem',
    }

    // Container Style
    const containerStyle = {
        padding: '0% 10%',
    }

    // Copyright Background Style
    const copyrightBackgroundStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }

    // Realtime year
    const currentDate = new Date();
    const copyrightYear = currentDate.getFullYear();

    return (
        <div style={backgroundImageStyle}>
            <Container style={containerStyle}>
                <Row>
                    {/* About us */}
                    <Col lg={4} className='lato-regular p-lg-5 px-5 py-3'>
                        <p className='font-medium light-blue mb-lg-5 mb-3'>
                            About Us
                        </p>
                        <p className='font-regular white'>
                            We are the pioneers and largest Metal Label Provider in India. We provide Metal
                            Label services for wide range of industries including Pharma, Automobile, Asset
                            Tagging, Barcoding, Aerospace, Marine.
                        </p>
                        <p className='font-regular white'>
                            We have years of experience in providing
                            customized Metal Label solutions to our
                            clients.
                        </p>
                    </Col>

                    {/* Services */}
                    <Col lg={4} className='lato-regular p-lg-5 px-5 py-3'>
                        <p className='font-medium light-blue mb-lg-5 mb-3'>
                            Services
                        </p>
                        <a href='./metal-pro' className='font-regular white text-decoration-none'>
                            METALPRO
                        </a><br />
                        <a href='./metal-photo' className='font-regular white text-decoration-none'>
                            METALPHOTO
                        </a><br />
                        <a href='./design-wallpaper' className='font-regular white text-decoration-none'>
                            DESIGN & WALLPAPER
                        </a>
                    </Col>

                    {/* Contact us */}
                    <Col lg={4} className='lato-regular p-lg-5 px-5 py-3'>
                        <p className='font-medium light-blue mb-lg-5 mb-3'>
                            Contact Us
                        </p>
                        <p className='font-regular white'>
                            Strokes India,<br />
                            691/A/2, Bibvewadi Industrial Estate No. 9,
                            2nd Floor, Pune Satara Road, Pune - 411037,
                            Maharashtra, India.
                        </p>
                        <p className='font-regular white'>
                            Contact:<br />
                            <a href='tel:+91 9822065602' className='text-decoration-none white'>+91-9822065602</a><br />
                            <a href='tel:+91 9075095604' className='text-decoration-none white'>+91-9075095604</a><br />
                            <a href='tel:+91 9075095601' className='text-decoration-none white'>+91-9075095601</a>
                        </p>
                        <p className='font-regular white'>
                            Email:<br />
                            <a href='mailto:strokesindia@gmail.com' className='text-decoration-none white'>strokesindia@gmail.com</a><br />
                            <a href='mailto:sales.strokesindia@gmail.com' className='text-decoration-none white'>sales.strokesindia@gmail.com</a>
                        </p>
                    </Col>
                </Row>
            </Container>

            {/* Copyright */}
            <div className='lato-regular font-regular white text-center' style={copyrightBackgroundStyle}>
                <p className='py-4 m-0'>Copyright {copyrightYear} | Strokes India | All Rights Reserved</p>
            </div>
        </div>
    );
}

export default Footer;