import { React, useState, useEffect } from 'react';
import  PropTypes from 'prop-types';

import '../static/styles/Common.css';

function Hero(props) {
    // Mobile View Port Flag
    const [isMobile, setIsMobile] = useState(false);

    // Listener for Mobile View Port
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 991.98);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Background Image Style
    const backgroundImageStyle = {
        width: '100%',
        height: isMobile ? props.mobileHeight : '75vh',
        objectFit: 'cover',
        objectPosition: props.position,
    }

    // Overlay Style
    const overlayStyle = {
        position: 'absolute',
        width: '100%',
        height: isMobile ? '90vh': '75vh',
        top: '0',
    }

    // Overlay Column Style
    const overlayColumnStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: isMobile ? '90vh': '75vh',
    }

    return (
        <>
            <img src={props.image} alt='Metal Pro Hero' style={backgroundImageStyle} />
            <div style={overlayStyle}>
                <div style={overlayColumnStyle}>
                    <div style={props.style}>
                        {props.content}
                    </div>
                </div>
            </div>
        </>
    );
}

Hero.propTypes = {
    content: PropTypes.object,
    style: PropTypes.object,
    image: PropTypes.string,
    position: PropTypes.string,
    mobileHeight: PropTypes.string,
};

export default Hero;