import { React, useState, useEffect } from 'react';
import { Container, Row, Col, Image, Carousel } from 'react-bootstrap';
import  PropTypes from 'prop-types';

import NavigationBar from '../components/NavigationBar';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

import MetalPhotoPageBackground from '../static/assests/background/metal-photo-page-background.jpg';
import MetalPhotoBlackLogo from '../static/assests/logos/metal-photo-black-logo.png';
import MetalPhotoCrossSection from '../static/assests/metal-photo/cross-section.png';
import DurableConstructionIcon from  '../static/assests/icons/durable-construction-icon.png';
import VariableInformationCapabilityIcon from  '../static/assests/icons/variable-information-capability-icon.png';
import PhotographicResolutionImageIcon from  '../static/assests/icons/photographic-resolution-image-icon.png';
import ProvenPerformanceIcon from  '../static/assests/icons/proven-performance-icon.png';
import WeatherResistanceIcon from  '../static/assests/icons/weather-resistance-icon.jpg';
import AbrasionResistanceIcon from  '../static/assests/icons/abrasion-resistance-icon.jpg';
import TemperatureResistanceIcon from  '../static/assests/icons/temperature-resistance-icon.jpg';
import CorrosionResistanceIcon from  '../static/assests/icons/corrosion-resistance-icon.jpg';
import ChemicalResistanceIcon from  '../static/assests/icons/chemical-resistance-icon.jpg';

import '../static/styles/Common.css';

function MetalPhoto(props) {
    // Mobile View Port Flag
    const [isMobile, setIsMobile] = useState(false);

    // Listener for Mobile View Port
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768.98);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Container Style
    const containerStyle = {
        padding: '0% 10%',
    }

    // Hero Style
    const heroStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        width: isMobile ? '60%' : null,
        borderRadius: '10px',
    }

    // Applications Container Style
    const applicationsContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alighItems: 'center',
        padding: '0% 10%',
    }

    // Names of Applications
    const applicationNames = [
        {
            id: 1,
            name: 'DEFENCE',
        },
        {
            id: 2,
            name: 'AEROSPACE',
        },
        {
            id: 3,
            name: 'TRANSPORTATION',
        },
        {
            id: 4,
            name: 'MARINE',
        },
        {
            id: 5,
            name: 'ENERGY',
        },
        {
            id: 6,
            name: 'HEAVY EQUIPMENT',
        }
    ]
    
    // Sample Gallery
    const sampleGallery = [
        {
            id: 1,
            name: 'gallery-1.jpg'
        },
        {
            id: 2,
            name: 'gallery-2.jpg'
        },
        {
            id: 3,
            name: 'gallery-3.jpg'
        },
        {
            id: 4,
            name: 'gallery-4.jpg'
        },
        {
            id: 5,
            name: 'gallery-5.jpg'
        },
        {
            id: 6,
            name: 'gallery-6.jpg'
        },
        {
            id: 7,
            name: 'gallery-7.jpg'
        },
        {
            id: 8,
            name: 'gallery-8.jpg'
        },
        {
            id: 9,
            name: 'gallery-9.jpg'
        },
        {
            id: 10,
            name: 'gallery-10.jpg'
        }
    ]

    return (
        <>
            {/* Navigation Bar */}
            <NavigationBar navKey={props.navKey} />

            {/* Hero */}
            <Hero 
                image={MetalPhotoPageBackground}
                style={heroStyle}
                content={heroContent()}
                mobileHeight={'90vh'}
            />

            {/* Metal Photo */}
            <Container fluid='lg' className='lato-regular text-center py-5' style={containerStyle}>
                <p className='lato-bold font-medium py-5'>
                    Metalphoto® is photosensitive, anodized aluminium used to make extremely durable, ultra-high-resolution nameplates, 
                    labels, and control panels with variable data such as barcodes, QR codes or serialized information at scale.
                </p>
                <Row>
                    <Col md={6} className='py-4'>
                        <Image src={MetalPhotoCrossSection} width={350} height={350} fluid />
                    </Col>
                    <Col md={6} className='py-4'>
                        <p className='lato-regular font-regular text-md-start'>
                            Metalphoto's durability comes from its archival image - which is sealed inside of the anodized aluminium, providing unmatched 
                            resistance to long-term sunlight, saltwater, high-temperature and/or harsh chemical resistance. A US Navy study of 73 materials 
                            found Metalphoto to earn the highest possible score on more tests than any other material evaluated. 
                        </p>
                        <p className='lato-regular font-regular text-md-start'>
                            Its unique, photographic imaging process economically creates a ultra high-resolution graphic and variable 
                            information for product identification and asset tracking application such as barcodes/QR codes, anti-conterfeit security 
                            and serialized nameplates at a production scale unmatched by other imaging technologies.
                        </p>
                    </Col>
                </Row>
            </Container>

            {/* Why Use Metalphoto? */}
            <div className='py-5' style={{backgroundColor: '#F2F2F2'}}>
                <p className='lato-bold font-large text-center pb-4'>
                    Why use Metalphoto?
                </p>
                <Container style={containerStyle}>
                    <Row>
                        <Col md={6} lg={3} className='text-center pb-5'>
                            <Image src={DurableConstructionIcon} width={80} height={80} />
                            <p className='lato-bold font-regular py-2'>
                                Durable<br />Construction
                            </p>
                            <p className='lato-regular font-regular'>
                                Metalphoto's silver-halide-based image is sealed inside of anodized aluminium, making it readable 
                                after prolonged exposure to harsh operating conditions including weather/ sunlight, heat, abrasion, chemicals & salt-spray.
                            </p>
                        </Col>
                        <Col md={6} lg={3} className='text-center pb-5'>
                            <Image src={VariableInformationCapabilityIcon} width={80} height={80} />
                            <p className='lato-bold font-regular py-2'>
                                Variable<br />Information Capability
                            </p>
                            <p className='lato-regular font-regular'>
                                Metalphoto labels and nameplates support item-unique serialize data, one-of-a-kind instructions or barcodes 
                                of any symbology that reduce data entry errors and speed asset tracking.
                            </p>
                        </Col>
                        <Col md={6} lg={3} className='text-center pb-5'>
                            <Image src={PhotographicResolutionImageIcon} width={80} height={80} />
                            <p className='lato-bold font-regular py-2'>
                                Photographic<br />Resolution
                            </p>
                            <p className='lato-regular font-regular pb-5'>
                                Metalphoto's high-image resolution makes it possible to mark small items or surfaces and 
                                provides the option of security printing features such as micro-text and watermarks.
                            </p>
                        </Col>
                        <Col md={6} lg={3} className='text-center pb-5'>
                            <Image src={ProvenPerformanceIcon} width={80} height={80} />
                            <p className='lato-bold font-regular py-2'>
                                Proven<br />Performance
                            </p>
                            <p className='lato-regular font-regular'>
                                Metalphoto has proven itself again and again to major OEMs and government organizations. As a result, 
                                most major government, industrial and military organizations specify Metalphoto.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* When to Use Metalphoto? */}
            <Container className='py-5' style={containerStyle}>
                <p className='lato-bold font-large text-center pb-4'>
                    Why use Metalphoto?
                </p>
                <Row>
                    <Col xs={6} md={6} lg className='text-center py-2'>
                        <Image src={WeatherResistanceIcon} width={80} height={80} />
                        <p className='lato-bold font-regular fw-bold py-3'>
                            Weather/UV<br />Resistance
                        </p>
                    </Col>
                    <Col xs={6} md={6} lg className='text-center py-2'>
                        <Image src={AbrasionResistanceIcon} width={80} height={80} />
                        <p className='lato-bold font-regular fw-bold py-3'>
                            Abrasion<br />Resistance
                        </p>
                    </Col>
                    <Col xs={6} md={6} lg className='text-center py-2'>
                        <Image src={TemperatureResistanceIcon} width={80} height={80} />
                        <p className='lato-bold font-regular fw-bold py-3'>
                            Temperature<br />Resistance
                        </p>
                    </Col>
                    <Col xs={6} md={6} lg className='text-center py-2'>
                        <Image src={CorrosionResistanceIcon} width={80} height={80} />
                        <p className='lato-bold font-regular fw-bold py-3'>
                            Corrosion<br />Resistance
                        </p>
                    </Col>
                    <Col xs={12} md={12} lg className='text-center py-2'>
                        <Image src={ChemicalResistanceIcon} width={80} height={80} />
                        <p className='lato-bold font-regular fw-bold py-3'>
                            Chemical<br />Resistance
                        </p>
                    </Col>
                </Row>
            </Container>
            
            {/* Metalphoto Applications */}
            <div className='py-5' style={{backgroundColor: '#F2F2F2'}}>
                <p className='lato-bold font-large text-center pb-4'>
                    Metalphoto Applications
                </p>
                <Container className='d-flex' style={applicationsContainerStyle}>
                    {applicationNames.map(project => generateApplications(project))}
                </Container>
            </div>

            {/* Metalphoto Sample Gallery */}
            <Container className='py-5 gallery'>
                <p className='lato-bold font-large text-center pb-4'>
                    Metalphoto Sample Gallery
                </p>
                <Carousel className='py-2 text-center' variant='dark' slide={false}>
                    {sampleGallery.map(project => generateImages(project))}
                </Carousel>
            </Container>

            {/* Footer */}
            <Footer />
        </>
    );
}

// Hero Element Center Content
function heroContent() {
    return (
        <div className='text-center p-4'>
            <Image src={MetalPhotoBlackLogo}  width={250} height={120} fluid />
            <p className='lato-regular font-medium fw-bold py-1 m-0'>
                Trusted where permanent identification is critical
            </p>
        </div>
    );
}

// Generating Industry Names
function generateApplications(project) {
    const applicationsPillStyle = {
        border: '2px solid #3498DB',
        borderRadius: '10px',
        backgroundColor: '#3498DB',
    }

    return (
        <p className='lato-bold font-regular white p-2 p-md-3 m-1' style={applicationsPillStyle} key={project.id}>
            {project.name}
        </p>
    );
}

// Generating Images for Carousel
function generateImages(project) {
    const importAll = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace('./', '')] = r(item));
        });
        return images;
    };
      
    const images = importAll(require.context('../static/assests/metal-photo/', false, /\.(png|jpe?g|svg)$/));

    return (
        <Carousel.Item key={project.id}>
            <Image src={images[project.name]} fluid className='carousel-image' />
        </Carousel.Item>
    );
}

MetalPhoto.propTypes = {
    navKey: PropTypes.string,
};

export default MetalPhoto;