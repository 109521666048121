import { React } from 'react';
import { Container, Row, Col, Image, Carousel } from 'react-bootstrap';
import  PropTypes from 'prop-types';

import NavigationBar from '../components/NavigationBar';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

import GraphicDesignPageBackground from '../static/assests/background/graphic-design-page-background.jpg';
import CustomizedWallpaperBackground from '../static/assests/background/customized-wallpaper-background.jpg';
import GraphicDesignServicesIcon from  '../static/assests/icons/graphic-design-services-icon.png';
import PrintServicesIcon from  '../static/assests/icons/print-services-icon.png';
import IndoorOutdoorMediaIcon from  '../static/assests/icons/indoor-outdoor-media-icon.png';

import '../static/styles/Common.css';

function DesignWallpaper(props) {
    // Container Style
    const containerStyle = {
        padding: '0% 10%',
    }

    // Customized Wallpaper Style
    const customizedWallpaperStyle = {
        position: 'absolute',
        top: '2rem',
        width: '100%',
    }

    // Sample Wallpapers
    const sampleWallpapers = [
        {
            id: 1,
            name: 'wallpaper-1.jpg'
        },
        {
            id: 2,
            name: 'wallpaper-2.jpg'
        },
        {
            id: 3,
            name: 'wallpaper-3.jpg'
        },
        {
            id: 4,
            name: 'wallpaper-4.jpg'
        }
    ]

    return (
        <>
            {/* Navigation Bar */}
            <NavigationBar navKey={props.navKey} />

            {/* Hero */}
            <Hero 
                image={GraphicDesignPageBackground}
                position={'30% 0%'}
                mobileHeight={'60vh'}
            />

            {/* Graphic Design & Print Services */}
            <Container className='py-5' style={containerStyle}>
                <p className='lato-bold font-large text-center light-blue pb-4'>
                    Graphic Design & Print Services
                </p>
                <Row>
                    <Col md={6} lg className='text-center py-4'>
                        <Image src={GraphicDesignServicesIcon} width={60} height={60} />
                        <p className='lato-regular font-regular light-blue fw-bold py-3'>
                            Graphic Design Services
                        </p>
                        <p className='lato-regular font-regular'>
                            Corporate Identity<br />
                            Corporate Stationery<br />
                            Brochures<br />
                            Product Catalogue<br />
                            Marketing Material<br />
                            Promotional Material
                        </p>
                    </Col>
                    <Col md={6} lg className='text-center py-4'>
                        <Image src={PrintServicesIcon} width={60} height={60} />
                        <p className='lato-regular font-regular light-blue fw-bold py-3'>
                            Print Services
                        </p>
                        <p className='lato-regular font-regular'>
                            Visiting Cards<br />
                            Customised Visiting Cards<br />
                            Office Stationery<br />
                            Screen Printing<br />
                            Industrial Stickers<br />
                            PVC, PET, pp
                        </p>
                    </Col>
                    <Col md={6} lg className='text-center py-4'>
                        <Image src={IndoorOutdoorMediaIcon} width={60} height={60} />
                        <p className='lato-regular font-regular light-blue fw-bold py-3'>
                            Indoor & Outdoor Media
                        </p>
                        <p className='lato-regular font-regular'>
                            Standee<br />
                            Exhibition Panels<br />
                            Posters & banners<br />
                            Signages<br />
                            Vehicle & Fleet Branding<br />
                            Environment Branding<br />
                            In Shop Branding (ISB)<br />
                            Glow Sign Box (GSB)
                        </p>
                    </Col>
                </Row>
            </Container>

            {/* Customized Wallpapers */}
            <div style={{position: 'relative'}}>
                <Image src={CustomizedWallpaperBackground} fluid />
                <p className='lato-regular font-large white text-center' style={customizedWallpaperStyle}>
                    Customized Wallpapers
                </p>
            </div>

            {/* Looking for Wallpaper Ideas? */}
            <div className='py-5' style={{backgroundColor: '#EE5A22'}}>
                <Container style={containerStyle}>
                    <p className='lato-regular font-large white text-center'>
                        Looking for wallpaper ideas?
                    </p>
                    <Row>
                        <Col md={12} lg className='p-3'>
                            <p className='lato-regualr font-regular white'>
                                Try a floral feature wall in the bedroom, statement geometrics in the living room or a folksy design 
                                for a kid's room; there really are so many unique ways to hang wallpaper in your home. So, 
                                from pretty florals to trompel'oeil to bold (and slightly wacky) prints, get inspired by our pick of the 
                                best wallpaper designs. You won't be disappointed.
                            </p>
                        </Col>
                        <Col md={12} lg className='p-3'>
                            <p className='lato-regualr font-regular white'>
                                In the 'Customized Wallpaper' activity, we can produce the options for 3D designs, Kids Designs, 
                                Vastu Designs, Curtains and Floor graphics etc., for which we are using the German non-oven material, durable 
                                and Fire resistant, environment friendly.
                            </p>
                        </Col>
                    </Row>
                    <div className='gallery'>
                        <Carousel className='py-2 text-center' variant='light' slide={false}>
                            {sampleWallpapers.map(project => generateImages(project))}
                        </Carousel>
                    </div>
                </Container>
            </div>

            {/* Footer */}
            <Footer />
        </>
    );
}

// Generating Images for Carousel
function generateImages(project) {
    const importAll = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace('./', '')] = r(item));
        });
        return images;
    };
      
    const images = importAll(require.context('../static/assests/design-wallpaper/', false, /\.(png|jpe?g|svg)$/));

    return (
        <Carousel.Item key={project.id}>
            <Image src={images[project.name]} fluid className='carousel-image' />
        </Carousel.Item>
    );
}

DesignWallpaper.propTypes = {
    navKey: PropTypes.string,
};

export default DesignWallpaper;