import { React, useState } from 'react';
import { Form, Row, Col, Button, ToggleButton, ToggleButtonGroup, Modal, Image } from 'react-bootstrap';
import axios from 'axios';

import SuccessGIF from '../static/assests/icons/success.gif';

import '../static/styles/Common.css';

function RequestService() {
    // Validation State
    const [validated, setValidated] = useState(false);

    // Variable to store value of selected service
    const [selectedService, setSelectedService] = useState(null);

    // Variable to control the visibility of modal
    const [modalShow, setModalShow] = useState(false);

    // Variable to control the visibility of select service validation
    const [showSelectValidation, setShowSelectValidation] = useState(false);

    // Select Buttons Style
    let toggleButtonStyle = {
        backgroundColor: '#CCCCCC',
        borderRadius: '0',
        border: '0',
        transition: 'color 0.3s ease',
    }

    // Select Button Checked Style
    let toggleButtonCheckedStyle = {
        backgroundColor: '#CCCCCC',
        borderRadius: '0',
        border: '0',
        color: '#EE5A22',
    }

    // Select Button Validation Style
    let serviceValidationStyle = {
        color: 'red',
        display: showSelectValidation ? 'inline' : 'none',
    }

    // Validation Logic
    const handleSubmit = async (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false || selectedService === null) {
            if (selectedService === null) {
                setShowSelectValidation(true)
            }
            event.stopPropagation();
        } else {
            // Displaying the modal
            setModalShow(true);

            try {
                const formData = {
                    firstName: form.elements.firstName.value,
                    lastName: form.elements.lastName.value,
                    companyName: form.elements.companyName.value,
                    email: form.elements.email.value,
                    address: form.elements.address.value,
                    phoneNumber: form.elements.phoneNumber.value,
                    service: selectedService,
                    moreDetails: form.elements.moreDetails.value
                };

                const response = await axios.post('https://strokesindia.com/mail.php', formData);
                console.log(response.data);

            } catch (error) {
                console.error('Error Sending Data: ', error);
            }

            // Hide the modal after 2 seconds
            setTimeout(() => {
                setModalShow(false);
                window.location.reload();
            }, 2000);

            // Resetting the form and other states
            form.reset();
            setValidated(false);
            setSelectedService(null);
        }

        // Enable validation
        setValidated(true);
    };

    // Toggle Button Click Handler
    const handleToggle = (value) => {
        setSelectedService(value === selectedService ? null : value);
        setShowSelectValidation(false);
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} xs={12} sm={6} controlId="firstName" className='pt-2'>
                    <Form.Label className='lato-regular light-gray'>FIRST NAME</Form.Label>
                    <Form.Control type="text" maxLength={25} required />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} controlId="lastName" className='pt-2'>
                    <Form.Label className='lato-regular light-gray'>LAST NAME</Form.Label>
                    <Form.Control type="text" maxLength={25} required />
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="companyName">
                <Form.Label className='lato-regular light-gray'>NAME OF COMPANY</Form.Label>
                <Form.Control type='text' maxLength={75} required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
                <Form.Label className='lato-regular light-gray'>EMAIL ADDRESS</Form.Label>
                <Form.Control type='email' maxLength={75} required />
            </Form.Group>

            <Row className="mb-3">
                <Form.Group as={Col} xs={12} sm={6} controlId="address" className='pt-2'>
                    <Form.Label className='lato-regular light-gray'>ADDRESS</Form.Label>
                    <Form.Control type='text' maxLength={200} required />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} controlId="phoneNumber" className='pt-2'>
                    <Form.Label className='lato-regular light-gray'>PHONE NUMBER</Form.Label>
                    <Form.Control type='tel' minLength={10} maxLength={10} required />
                </Form.Group>
            </Row>

            <Form.Group as={Row} lg={12} className="mb-3" controlId="service" required>
                <Form.Label className='lato-regular light-gray'>SELECT REQUIRED SERVICE&nbsp;<span style={serviceValidationStyle}> (PLEASE SELECT A SERVICE)</span>
                </Form.Label>
                <br />
                <ToggleButtonGroup as={Row} xs={3} md={6} name='service'>
                    <ToggleButton
                        id='metalLabels'
                        value='Metal Labels'
                        className='light-gray m-2 custom-toggle-button'
                        style={selectedService === 'Metal Labels' ? toggleButtonCheckedStyle : toggleButtonStyle}
                        checked={selectedService === 'Metal Labels'}
                        onChange={() => handleToggle('Metal Labels')}
                        >
                        Metal Labels
                    </ToggleButton>
                    <ToggleButton
                        id='wallpapers'
                        value='Wallpaper'
                        className='light-gray m-2 custom-toggle-button'
                        style={selectedService === 'Wallpaper' ? toggleButtonCheckedStyle : toggleButtonStyle}
                        checked={selectedService === 'Wallpaper'}
                        onChange={() => handleToggle('Wallpaper')}
                        >
                        Wallpaper
                    </ToggleButton>
                    <ToggleButton
                        id='designPrint'
                        value='Design & Print'
                        className='light-gray m-2 custom-toggle-button'
                        style={selectedService === 'Design & Print' ? toggleButtonCheckedStyle : toggleButtonStyle}
                        checked={selectedService === 'Design & Print'}
                        onChange={() => handleToggle('Design & Print')}
                        >
                        Design & Print
                        </ToggleButton>
                    <ToggleButton
                        id='others'
                        value='Others'
                        className='light-gray m-2 custom-toggle-button'
                        style={selectedService === 'Others' ? toggleButtonCheckedStyle : toggleButtonStyle}
                        checked={selectedService === 'Others'}
                        onChange={() => handleToggle('Others')}
                        >
                        Others
                    </ToggleButton>
                </ToggleButtonGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="moreDetails">
                <Form.Label className='lato-regular light-gray'>MORE DETAILS</Form.Label>
                <Form.Control as='textarea' required />
            </Form.Group>

            <Button variant="primary" type="submit" className='submit-btn'>
                SUBMIT
            </Button>

            {/* Modal */}
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Success
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <Image src={SuccessGIF} fluid />
                    <p className='lato-regular font-medium'>
                        Successfully submitted your request!
                    </p>
                </Modal.Body>
            </Modal>
        </Form>
    );
}

export default RequestService;