import { React } from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';

import QualityAssuranceIcon from '../static/assests/icons/quality-assurance-icon.png';
import TimelyServicesIcon from '../static/assests/icons/timely-service-icon.png';
import CustomSolutionsIcon from '../static/assests/icons/custom-solutions-icon.png';

import '../static/styles/Common.css';
import '../static/styles/AboutQualities.css';

function AboutQualities() {
    // Container Style
    const containerStyle = {
        padding: '0% 10%',
    }

    return (
        <Container style={containerStyle}>
            <Row>
                <Col md={6} lg={4} className='text-center p-lg-5 p-3'>
                    <div className='icon-and-title'>
                        <Image className='icon' src={QualityAssuranceIcon} width={100} height={100} />

                        <p className='lato-regular font-medium py-3'>
                            Quality Assurance
                        </p>
                    </div>

                    <p className='lato-regular font-regular'>
                        We understand the business objectives and will work within your budget and timeline to craft the solution that is best for you.
                    </p>
                </Col>
                <Col md={6} lg={4} className='text-center p-lg-5 p-3'>
                    <div className='icon-and-title'>
                        <Image className='icon' src={TimelyServicesIcon} width={100} height={100} />

                        <p className='lato-regular font-medium py-3'>
                            Timely Services
                        </p>
                    </div>

                    <p className='lato-regular font-regular'>
                        When it comes to implementing a design, we have an array of options ranging from screen Printing to Digital and vinyl to metal labels.
                    </p>
                </Col>
                <Col md={6} lg={4} className='text-center p-lg-5 p-3'>
                    <div className='icon-and-title'>
                        <Image className='icon' src={CustomSolutionsIcon} width={100} height={100} />

                        <p className='lato-regular font-medium py-3'>
                            Customised Solutions
                        </p>
                    </div>

                    <p className='lato-regular font-regular'>
                        Metal photo and acrylic products are ideal gifts to present on special occasions, and birthdays at the same time very useful in day-to-day life.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutQualities;