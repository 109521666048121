import { React } from 'react';
import { Container, Row, Col, Image, Carousel } from 'react-bootstrap';
import  PropTypes from 'prop-types';

import NavigationBar from '../components/NavigationBar';
import Hero from '../components/Hero';
import AboutQualities from '../components/AboutQualities';
import Footer from '../components/Footer';

import AboutUsPageBackground from '../static/assests/background/about-us-page-background.jpg';
import Certificate from '../static/assests/about/certificate.jpg';

import '../static/styles/Common.css';

function AboutUs(props) {
    // Container Style
    const containerStyle = {
        padding: '0% 10%',
    }

    // Hero Style
    const heroStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderRadius: '10px',
        position: 'absolute',
        bottom: '0',
    }

    // Team Gallery
    const teamGallery = [
        {
            id: 1,
            name: 'team-1.jpg'
        },
        {
            id: 2,
            name: 'team-2.jpg'
        },
        {
            id: 3,
            name: 'team-3.jpg'
        },
        {
            id: 4,
            name: 'team-4.jpg'
        },
        {
            id: 5,
            name: 'team-5.jpg'
        },
        {
            id: 6,
            name: 'team-6.jpg'
        },
        {
            id: 7,
            name: 'team-7.jpg'
        },
        {
            id: 8,
            name: 'team-8.jpg'
        },
        {
            id: 9,
            name: 'team-9.jpg'
        },
        {
            id: 10,
            name: 'team-10.jpg'
        },
        {
            id: 11,
            name: 'team-11.jpg'
        }
    ]

    return (
        <>
            {/* Navigation Bar */}
            <NavigationBar navKey={props.navKey} />

            {/* Hero */}
            <Hero 
                image={AboutUsPageBackground}
                content={heroContent()}
                style={heroStyle}
                mobileHeight={'90vh'}
            />

            {/* About */}
            <Container style={containerStyle} className='py-5'>
                <p className='lato-regular font-medium text-center'>
                    Strokes India is a strategic design, in-depth print and signage solution provider to convey adept business identity of associations.
                </p>
                <Row className='text-center'>
                    <Col md={12} lg>
                        <p className='lato-regular font-regular p-md-4 p-2 text-lg-start'>
                            We are aware that a well-established business is not a single secluded entity but a systematic approach that 
                            incorporates multitasking endeavours worked out adeptly. This is where Strokes confluences with organizations to present the
                        </p>
                    </Col>
                    <Col md={12} lg>
                        <p className='lato-regular font-regular p-md-4 p-2 text-lg-start'>
                            business identity through the plethora of media choices. Strokes India accommodates a range of day-to-day evolutionary design 
                            and print solutions to the latest metal label alike.
                        </p>
                    </Col>
                </Row>
            </Container>

            {/* About Qualities */}
            <div style={{backgroundColor: '#E6E6E6'}}>
                <AboutQualities />
            </div>

            {/* Certificates */}
            <Container style={containerStyle} className='py-5 text-center'>
                <p className='lato-bold font-large'> 
                    ISO Certification
                </p>
                <p className='lato-regular font-regular'>
                    To ensure every product we design, develop, and manufacture meets 
                    strict quality standards, we have adapted our processes and practices 
                    to strictly adhere to ISO 9001:2015 standards.
                </p>
                <Image src={Certificate} fluid width={200} height={500} />
            </Container>

            {/* Our Infrastructure and Team */}
            <div style={{backgroundColor: '#E6E6E6'}} className='py-5'>
                <Container style={containerStyle} className='gallery'>
                    <p className='lato-bold font-large text-center'> 
                        Our Infrastructure, Team and Events
                    </p>
                    <Carousel className='py-2 text-center' variant='dark' slide={false}>
                        {teamGallery.map(project => generateImages(project))}
                    </Carousel>
                </Container>
            </div>

            {/* Footer */}
            <Footer />
        </>
    );
}

// Hero Element Center Content
function heroContent() {
    return (
        <p className='montserrat-bold font-title text-center white p-4 m-0'>
            About Us
        </p>
    );
}

// Generating Images for Carousel
function generateImages(project) {
    const importAll = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace('./', '')] = r(item));
        });
        return images;
    };
      
    const images = importAll(require.context('../static/assests/about/', false, /\.(png|jpe?g|svg)$/));

    return (
        <Carousel.Item key={project.id}>
            <Image src={images[project.name]} fluid className='carousel-image' />
        </Carousel.Item>
    );
}

AboutUs.propTypes = {
    navKey: PropTypes.string,
};

export default AboutUs;