import { React } from 'react';
import { Row, Col, Container, Button, Image } from 'react-bootstrap';
import  PropTypes from 'prop-types';

import NavigationBar from '../components/NavigationBar';
import HeroCarousel from '../components/HeroCarousel';
import AboutQualities from '../components/AboutQualities';
import Footer from '../components/Footer';

import SteelBackground from '../static/assests/background/steel-background.png';
import MetalProShadowLogo from '../static/assests/logos/metal-pro-logo-shadow.png';
import MetalPhotoBlackLogo from '../static/assests/logos/metal-photo-black-logo.png';
import MetalPhotoCrossSection from '../static/assests/metal-photo/cross-section.png';
import HomeDesignIcon from '../static/assests/icons/design-icon.svg';
import HomeWallpaperIcon from '../static/assests/icons/wallpaper-icon.svg';

import '../static/styles/Common.css';

function Home(props) {
    // Container Style
    const containerStyle = {
        padding: '0% 10%',
    }

    // Design and Wallpaper Button Center Style
    const designWallpaperBtnStyle = {
        position: 'absolute',
        bottom: '3%',
        left: '50%',
        transform: 'translateX(-50%)',
    }

    return (
        <>
            {/* Navigation Bar */}
            <NavigationBar navKey={props.navKey} />

            {/* Hero Carousel */}
            <HeroCarousel />

            {/* About Us */}
            <Container fluid='lg' className='lato-regular font-medium text-center py-5' style={containerStyle}>
                <p>
                    We would like to introduce ourselves as a Metal label and graphics company, with expertise in "METALPRO", "METALPHOTO" and Graphics activity.
                    With all the production facilities, such as designing and printing under one roof, all activities are done in-house in a single premises.
                </p>
                <p>
                    We are an ISO 9001:2015 company that has been involved in photographic printing and supplying aluminium-based metallic labels and nameplates, along with
                    non-metallic ones to various industries (processes and OEMs) for the last two decades.
                </p>
                <Button href='/about-us' className='custom-btn'>
                    KNOW MORE ABOUT US
                </Button>
            </Container>

            {/* Metal Pro */}
            <div className='py-5 text-center' style={{backgroundImage: `url(${SteelBackground})`}}>
                <Container style={containerStyle}>
                    <Image src={MetalProShadowLogo} width={360} height={120} fluid />
                    <p className='lato-regular font-medium py-5'>
                        METALPRO™ Labels is unique among graphic identification materials because it combines the benefits of durability, 
                        image resolution and variable data capability. METALPRO™ Labels is 100% developed and Make in India name plate product. 
                        We manufacture high-quality Aluminium Anodized Sheet which can be used for Screen printing and Digital printing processes.
                    </p>
                    <Button href='/metal-pro' className='custom-btn'>KNOW MORE ABOUT METALPRO</Button>
                </Container>
            </div>

            {/* Metal Photo */}
            <Container fluid='lg' className='lato-regular text-center py-5' style={containerStyle}>
                <Image src={MetalPhotoBlackLogo} width={300} height={60} />
                <p className='font-medium py-5'>
                    Metalphoto® is photosensitive, anodized aluminium used to make durable, high-resolution nameplates, labels, control panels and signs.
                </p>
                <Row>
                    <Col md={6} className='py-4'>
                        <Image src={MetalPhotoCrossSection} width={350} height={350} fluid />
                    </Col>
                    <Col md={6} className='py-4'>
                        <p className='lato-regular font-regular text-md-start'>
                            Metalphoto's durability comes from its image - which is sealed inside of the anodized aluminium, providing corrosion, 
                            sunlight, abrasion, temperature and chemical resistance. Its unique, photographic imaging process creates ultra-high 
                            resolution graphics and variable information - including machine-readable barcodes, anti-counterfeit security features 
                            and serialized data. 
                        </p>
                        <p className='lato-regular font-regular text-md-start'>
                            Since its development, Metalphoto has been trusted by leading OEMs, military and government organizations for nameplate, 
                            label and control panel applications where permanence, resolution, variable data or a combination of those characteristics is required.
                        </p>
                    </Col>
                </Row>
                <Button href='/metal-photo' className='custom-btn'>KNOW MORE ABOUT METALPHOTO</Button>
            </Container>
            
            {/* Design & Wallpaper */}
            <Row className='g-0' style={{position: 'relative'}}>
                <Col md={6} style={{backgroundColor: '#009DDF'}}>
                    <Container className='text-center py-5' style={containerStyle}>
                        <p className='lato-bold font-large white pb-4'>
                            Design & Print services
                        </p>
                        <Image src={HomeDesignIcon} width={120} height={120} />
                        <div className='lato-regular white py-5'>
                            <p className='fw-bold'>
                                We understand business objectives and will work within your budget 
                            </p>
                            <p>
                                We understand business objectives and will work within your budget and timeline to craft the solution that is best for you. 
                                When it comes to the Implementation of a design we have array of options ranges from screen printing to offset and from vinyl 
                                to metal photo to choose from.
                            </p>
                        </div>
                    </Container>
                </Col>
                <Col md={6} style={{backgroundColor: '#7AB62D'}}>
                    <Container className='text-center py-5' style={containerStyle}>
                        <p className='lato-bold font-large white pb-4'>
                            Stroke Wallpapers
                        </p>
                        <Image src={HomeWallpaperIcon} width={120} height={120} />
                        <div className='lato-regular white py-5'>
                            <p className='fw-bold'>
                                Looking for wallpaper ideas? 
                            </p>
                            <p>
                                Try a floral feature wall in the bedroom, statement geometrics in the living room or a folksy design for a kid's room; 
                                there really are so many unique ways to hang wallpaper in your home. So, from pretty florals to trompel'oeil to bold (and slightly wacky) 
                                prints, get inspired by our pick of the best wallpaper designs. You won't be disappointed.
                            </p>
                        </div>
                    </Container>
                </Col>
                <Button href='/design-wallpaper' className='custom-btn w-auto' style={designWallpaperBtnStyle}>KNOW MORE</Button>
            </Row>

            {/* Quality Assurance, Timely Services, and Customised Solutions */}
            <AboutQualities />

            {/* Footer */}
            <Footer />
        </>
    );
}

Home.propTypes = {
    navKey: PropTypes.string,
};

export default Home;