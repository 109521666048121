import { React } from 'react';
import { BrowserRouter, Routes, Route }  from 'react-router-dom';
import Home from './pages/Home';
import MetalPro from './pages/MetalPro';
import MetalPhoto from './pages/MetalPhoto';
import DesignWallpaper from './pages/DesignWallpaper';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route index element={<Home navKey='home' />} />
            <Route path='/home' element={<Home navKey='home' />} />
            <Route path='/metal-pro' element={<MetalPro navKey='metal-pro' />} />
            <Route path='/metal-photo' element={<MetalPhoto navKey='metal-photo' />} />
            <Route path='/design-wallpaper' element={<DesignWallpaper navKey='design-wallpaper' />} />
            <Route path='/about-us' element={<AboutUs navKey='about-us' />} />
            <Route path='/contact-us' element={<ContactUs navKey='contact-us' />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
