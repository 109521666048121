import { React, useEffect, useState } from 'react';
import { Carousel, Row, Col, Image } from 'react-bootstrap';

import MetalProBackground from '../static/assests/background/metal-pro-background.jpg';
import MetalPhotoBackground from '../static/assests/background/metal-photo-background.jpg';
import GraphicDesignBackground from '../static/assests/background/graphic-design-background.jpg';
import MetalProLogo from '../static/assests/logos/metal-pro-logo.png';
import MetalPhotoLogo from '../static/assests/logos/metal-photo-logo.png';

import '../static/styles/Common.css';

function HeroCarousel() {
    // Mobile View Port Flag
    const [isMobile, setIsMobile] = useState(false);

    // Listener for Mobile View Port
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767.98);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Background Image Style
    const backgroundImageStyle = {
        width: '100%',
        height: '100vh',
        objectFit: 'cover',
    }

    // Overlay Style
    const overlayStyle = {
        position: 'absolute',
        width: '100%',
        height: '100vh',
        top: '0',
    }

    // Overlay Column Style
    const overlayColumnStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: isMobile ? 'center' : 'end',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    }

    // Overlay Text Style
    const overlayTextStyle = {
        width: '70%',
    }

    return (
        <Carousel controls={false} indicators={false} className='montserrat-bold font-title white text-md-end text-center'>
            {/* Metal Pro Hero */}
            <Carousel.Item>
                <img src={MetalProBackground} alt='Strokes India Hero' style={backgroundImageStyle} />
                <div style={overlayStyle}>
                    <Row>
                        <Col sm={12} md={6} className='p-5' style={overlayColumnStyle}>
                            <p style={overlayTextStyle} className='m-0'>
                                Craft an enduring impression with
                            </p>
                            <Image src={MetalProLogo} className='py-3' width={300} height={100} fluid />
                        </Col>
                    </Row>
                </div>
            </Carousel.Item>

            {/* Metal Photo Hero */}
            <Carousel.Item>
                <img src={MetalPhotoBackground} alt='Strokes India Hero' style={backgroundImageStyle} />
                <div style={overlayStyle}>
                    <Row>
                        <Col sm={12} md={6} className='p-5' style={overlayColumnStyle}>
                            <Image src={MetalPhotoLogo} className='py-3' width={300} height={100} fluid />
                            <p style={overlayTextStyle} className='m-0'>
                                Trusted where permanent identification is critical
                            </p>
                        </Col>
                    </Row>
                </div>
            </Carousel.Item>

            {/* Graphic Design and Wallpaper Hero */}
            <Carousel.Item>
                <img src={GraphicDesignBackground} alt='Strokes India Hero' style={backgroundImageStyle} />
                <div style={overlayStyle}>
                    <Row>
                        <Col sm={12} md={6} className='p-5' style={overlayColumnStyle}>
                            <p style={overlayTextStyle} className='m-0'>
                                Graphic Design and Wallpapers - Where Quality and Style Converge
                            </p>
                        </Col>
                    </Row>
                </div>
            </Carousel.Item>
        </Carousel>
    );
}

export default HeroCarousel;